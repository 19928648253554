<template>
  <div class="promotion-page">
    <!-- 背景图片容器 -->
    <div class="background-image">
      <!-- Logo -->
      <div class="divLogo">
        <img src="../assets/logo.jpg" alt="Logo" class="logo">
      </div>
     
    </div>
    <div class="divtext" >
      <h2 >海量破解应用集聚平台</h2>
      <br/>
      <p>视频、直播、短视频、小说、漫画</p>
    </div>
    <!-- 下载按钮 -->
    <div class="download-buttons">
      <!-- 安卓下载按钮 -->
      <button class="android-button" @click="downloadApp">安卓下载</button>
      <!-- 苹果下载按钮 -->
      <button class="ios-button" @click="iosTg">苹果下载</button>
    </div>
  </div>
</template>

<style scoped>
/* 推广页面样式 */
.promotion-page {
  position: relative;
  height: 100vh; /* 设置页面高度为视口高度，保证背景图片充满整个屏幕 */
}

/* 背景图片样式 */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  animation: slideBackground 10s infinite; /* 背景图片轮播动画 */
}

/* Logo样式 */
.logo {
 
  left: 10px; 
  width: 60px;
  height: auto; 
}
.divtext{
  position: fixed;
  bottom: 60%; 
  text-align: center;
  z-index: 999; 
  width: 100%;
}

.divtext h2{
  margin: 0 auto;
  color: #51a351;
}

.divtext p{
  margin: 0 auto;
  color: #9262c9;
}

.divLogo{
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999; /* 确保按钮在最上层 */
  display: flex;

}

/* 下载按钮样式 */
.download-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999; /* 确保按钮在最上层 */
  display: flex;
}

.android-button,
.ios-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: max-content;
}

.android-button {
  background-color: #4CAF50; /* 绿色 */
  color: white;
}

.ios-button {
  background-color: #2196F3; /* 蓝色 */
  color: white;
}

@keyframes slideBackground {
  0% {
    background-image: url('../assets/00002.jpg');
  }
  50% {
    background-image: url('../assets/00004.jpg');
  }
  100% {
    background-image: url('../assets/00002.jpg');
  }
}
</style>

<script>
import { useRoute } from 'vue-router'
import router from '@/router/index'

export default {
  name: 'indexView',

  setup() {
    const route = useRoute()
    const key = route.query.code

    const iosTg = () => {
      router.push({ path: '/ios' })
    }

    const downloadApp = () => {
      if (key) {
        window.location.href = "/lyjd.apk?code=" + key
      } else {
        window.location.href = "/lyjd.apk"
      }
    }

    return {
      iosTg,
      downloadApp
    }
  }
}
</script>
